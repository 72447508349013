import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['lockedBy', 'locked', 'unlocked', 'cog', 'preview', 'workflow'];

  toggleLocked(lockedForMe) {
    const classesForLock = ['opacity-20', 'pointer-events-none'];
    this.unlockedTarget.classList.toggle('hidden', lockedForMe);
    this.lockedTarget.classList.toggle('hidden', !lockedForMe);
    classesForLock.forEach((className) => {
      this.cogTarget.classList.toggle(className, lockedForMe);
      this.previewTarget.classList.toggle(className, lockedForMe);
      this.workflowTarget.classList.toggle(className, lockedForMe);
    });
  }

  updateLockedBy(lockedByUserName) {
    this.lockedByTarget.innerText = `${lockedByUserName} is editing this campaign`;
  }
}
