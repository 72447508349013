import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    properties: Object,
  };

  static targets = ['usage', 'sample'];

  coreAttributeChanged(event) {
    const { coreAttributeId } = event.params;
    const newValue = event.target.value;

    const sampleTarget = this.sampleTargets.find((target) => target.dataset.coreAttributeId === `${coreAttributeId}`);
    const usageTarget = this.usageTargets.find((target) => target.dataset.coreAttributeId === `${coreAttributeId}`);

    if (newValue) {
      const newSample = this.propertiesValue[newValue].sample_value || 'No sample found';
      const newUsage = this.propertiesValue[newValue].usage_rate * 100;
      sampleTarget.textContent = newSample;
      usageTarget.value = newUsage;
    } else {
      sampleTarget.textContent = '';
      usageTarget.value = 0;
    }
  }
}
