const customStyleManager = (editor) => {
  const components = editor.DomComponents;

  components.addType('image', {
    model: {
      defaults: {
        unstylable: [
          'background-image', // Background
          'background-color',
          'background-size',
          'color', // Text
          'font-family',
          'font-size',
        ],
      },
    },
  });
};

const sectors = [
  {
    name: 'Text',
    open: true,
    properties: [
      'color',
      'font-family',
      {
        type: 'select',
        property: 'font-size',
        default: '1rem',
        options: [
          { id: '0.8rem', label: 'Small' },
          { id: '1rem', label: 'Normal' },
          { id: '1.5rem', label: 'Big' },
        ],
      },
    ],
  },
  {
    name: 'Background',
    open: true,
    properties: [
      {
        type: 'color',
        label: 'Color',
        property: 'background-color',
        default: '#FFFFFF',
      },
      {
        type: 'file',
        label: 'Image',
        property: 'background-image',
        default: 'none',
        functionName: 'url',
      },
      {
        type: 'select',
        label: 'Image fit',
        property: 'background-size',
        default: 'auto auto',
        options: [
          { id: 'auto auto', label: 'Auto' },
          { id: 'cover', label: 'Cover' },
          { id: 'contain', label: 'Contain' },
        ],
      },
    ],
  },
  {
    name: 'Sizing',
    open: true,
    properties: [
      {
        type: 'select',
        label: 'Width',
        property: 'width',
        default: 'auto',
        options: [
          { id: 'auto', label: 'Auto' },
          { id: '100%', label: 'Full' },
          { id: '50%', label: '1/2' },
          { id: '33.33%', label: '1/3' },
        ],
      },
      {
        type: 'select',
        label: 'Height',
        property: 'height',
        default: 'auto',
        options: [
          { id: 'auto', label: 'Auto' },
          { id: '50%', label: '1/2' },
          { id: '33.33%', label: '1/3' },
        ],
      },
    ],
  },
  {
    name: 'Alignment',
    open: true,
    properties: [
      'text-align',
      {
        type: 'radio',
        property: 'vertical-align',
        default: 'top',
        options: [
          { id: 'top', label: 'top' },
          { id: 'middle', label: 'middle' },
          { id: 'bottom', label: 'bottom' },
        ],
      },
    ],
  },
  {
    name: 'Border',
    open: true,
    properties: [
      {
        type: 'color',
        label: 'Color',
        property: 'border-color',
      },
      {
        type: 'select',
        label: 'Thickness',
        property: 'border-width',
        default: '0',
        options: [
          { id: '0', label: 'None' },
          { id: '1px', label: 'Thin' },
          { id: '3px', label: 'Thick' },
        ],
      },
      {
        extend: 'border-style',
        label: 'Line Style',
      },
      {
        type: 'select',
        label: 'Roundness',
        property: 'border-radius',
        default: '0',
        options: [
          { id: '0', label: 'None' },
          { id: '2px', label: 'Small' },
          { id: '4px', label: 'Medium' },
          { id: '8px', label: 'Large' },
          { id: '100%', label: 'Round' },
        ],
      },
    ],
  },
  {
    name: 'Spacing',
    open: true,
    properties: [
      {
        type: 'select',
        label: 'Padding',
        property: 'padding',
        default: '0',
        options: [
          { id: '0', label: 'None' },
          { id: '4px', label: 'Small' },
          { id: '12px 8px', label: 'Medium' },
          { id: '24px', label: 'Large' },
        ],
      },
      {
        type: 'select',
        label: 'Margin',
        property: 'margin',
        default: '0',
        options: [
          { id: '0', label: 'None' },
          { id: '4px', label: 'Small' },
          { id: '8px', label: 'Medium' },
          { id: '24px', label: 'Large' },
        ],
      },
    ],
  },
  {
    name: 'Opacity',
    open: true,
    properties: ['opacity'],
  },
];

export { customStyleManager, sectors };
