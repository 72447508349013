import { Controller } from '@hotwired/stimulus';
import { logger } from '@root/utilities/logger';
import tinymce from 'tinymce/tinymce';
import { customizeEditorToolbar, registerCustomIcons } from '@root/utilities/tinymce_utils';
// Connects to data-controller="template"
export default class extends Controller {
  connect() {
    this.setup('controller.connect');
  }

  disconnect() {
    this.tinymceDisconnect();
  }

  tinymceDisconnect() {
    try {
      tinymce.remove();
    } catch {
      logger.debug('Error removing TinyMCE, perhaps not present?');
    }
  }

  setup(event) {
    const self = this;
    logger.debug(`TinyMCE setup triggered on ${event}`);
    // If the editor is already set up there's no point in initing again, we return early.
    if (tinymce.activeEditor) {
      // This is added to manually show the editor in the case where navigating with browser buttons has resulted in the
      // editor being hidden.
      if (tinymce.activeEditor.isHidden()) {
        tinymce.activeEditor.show();
      }
      return;
    }

    tinymce.init({
      menubar: false,
      suffix: '.min',
      height: '650',
      base_url: '/assets/tinymce',
      toolbar: ['table formatting responsiveImage | code | kebab'],
      contextmenu: 'link image ctaContextMenu sectionContextMenu',
      plugins: ['image', 'link', 'code', 'lists', 'colorpicker', 'table'],
      selector: '.tinymce',
      // eslint-disable-next-line no-undef
      content_css: typeof editorCssPath === 'undefined' ? '' : editorCssPath,

      setup: (editor) => {
        registerCustomIcons(editor);
        customizeEditorToolbar(editor, self, {
          unsubscribe: false,
          segmentation: false,
          liquid: false,
          snippet: false,
        });
      },
    });
  }

  submit() {
    const form = this.formTarget;
    form.requestSubmit();
  }
}
