import { Module } from 'tabulator-tables';
import SearchInput from './search_input.svelte';

export default class CustomSearchModule extends Module {
  static moduleName = 'differentSearch';

  static moduleInitOrder = 10;

  constructor(table) {
    super(table);
    this.table = table;
    this.previousFilterValue = '';
    this.totalResults = null;
    this.timeout = null;
    this.searchComponent = null;
  }

  initialize() {
    // Create the search input component
    this.createSearchComponent();

    // The options for this particular module are in the 'customSearchOptions' property for the table (can add more options and get those if needed)
    this.searchComponent.$set({
      placeholder: this.options('customSearchOptions')?.placeholder,
    });

    if (this.options('customSearchOptions')?.initialFilter) {
      this.searchComponent.$set({
        value: this.options('customSearchOptions')?.initialFilter,
      });
      const filter = this.options('customSearchOptions')?.initialFilter;
      this.previousFilterValue = filter;
      this.totalResults = this.options('customSearchOptions')?.totalResults;
      this.table.setFilter([{ field: 'some_example_description', type: 'like', value: filter }]);
    }

    // There's no ajax event, so we need to listen to the 'dataLoaded' event and use a custom 'count' property in the table
    this.table.on('dataLoaded', () => {
      this.searchComponent.$set({
        // placeholder: this.options('customSearchOptions')?.placeholder,
        totalMatches: this.table.count || this.totalResults,
        isLoading: false,
        showResultsLabel: this.table.getFilters().length > 0 || this.options('customSearchOptions')?.initialFilter,
      });
    });
  }

  createSearchComponent() {
    // Find or create a container for the search input
    let searchContainer = document.querySelector('#tabulator-search-container');

    if (!searchContainer) {
      searchContainer = document.createElement('div');
      searchContainer.id = 'tabulator-search-container';
      this.table.element.parentNode.insertBefore(searchContainer, this.table.element);
    }

    this.searchComponent = new SearchInput({
      target: searchContainer,
    });

    this.searchComponent.$on('input', (event) => this.handleSearchInput(event.detail.value));
  }

  // Search with debounce
  handleSearchInput(filterValue) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (filterValue === this.previousFilterValue) {
        // Stop the loader
        if (this.searchComponent) this.searchComponent.$set({ isLoading: false });
        return;
      }
      this.previousFilterValue = filterValue;

      if (filterValue) {
        this.table.setFilter([{ field: 'some_example_description', type: 'like', value: filterValue }]);
      } else {
        this.table.clearFilter();
      }
    }, 500);
  }
}
