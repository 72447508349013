<script>
  import { createEventDispatcher } from "svelte";
  import PaginationButton from "./pagination_button.svelte";

  export let currentPage = 1;
  export let lastPage = 1;
  export let paginationButtonCount = 3;
  export let paginationOptions = [3, 6, 9];
  export let className = ""
  export let paginationSize

  const dispatch = createEventDispatcher();

  function setPage(page) {
    dispatch('pageChange', page);
  }

  function setPageSize(event) {
    paginationSize = parseInt(event.target.value, 10);
    dispatch('pageSizeChange', paginationSize);
  }

  // Calculate start and end pages to display
  $: startPage = Math.max(1, Math.min(currentPage - Math.floor(paginationButtonCount / 2),
    lastPage - paginationButtonCount + 1));
  $: endPage = Math.min(lastPage, startPage + paginationButtonCount - 1);
</script>

<!-- Main Pagination Component -->
<div class={`flex flex-row space-x-5 items-center ${className}`}>

  <!-- <p class="text-red-500 text-xs">DEBUG: {JSON.stringify(paginationOptions)}</p> -->

  <!-- Navigation -->
  <div class="flex flex-row space-x-3">
    <PaginationButton disabled={currentPage === 1} icon='chevron-left-double' onClick={() => setPage(1)}/>
    <PaginationButton disabled={currentPage === 1} icon='chevron-left' onClick={() => setPage(currentPage - 1)}/>
    {#each Array(endPage - startPage + 1).fill(0).map((_, i) => startPage + i) as page}
      <PaginationButton onClick={() => setPage(page)} active={currentPage === page} text={page}/>
    {/each}
    <PaginationButton disabled={currentPage === lastPage}
                      icon='chevron-right'
                      onClick={() => setPage(currentPage + 1)}/>
    <PaginationButton disabled={currentPage === lastPage}
                      icon='chevron-right-double'
                      onClick={() => setPage(lastPage)}/>
  </div>

  <!-- Items per page select -->
  <select bind:value={paginationSize} class="text-sm" on:change={setPageSize}>
    {#each paginationOptions as option}
      <option value={option}>{option} items per page</option>
    {/each}
  </select>

</div>
