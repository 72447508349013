// Settings if data was passed into the table
export const staticSettings = (data) => ({
  data,
});

// Settings if an endpoint was provided
export const ajaxSettings = (endpoint, currentPage, pageSize) => ({
  customSearch: true,
  // Pagination options
  footerElement: '#tabulator-footer',
  // paginationElement: document.getElementById('tabulator-pagination-singulate'), // the element to hold the pagination element
  pagination: true,
  paginationButtonCount: 5, // set the number of pages to show in the pagination area
  paginationInitialPage: currentPage, // optional parameter to set the initial page to load
  paginationMode: 'remote', // Enable remote pagination mode
  filterMode: 'remote', // send filter data to the server instead of processing locally

  // AJAX settings
  ajaxURL: endpoint,
  paginationSizeSelector: [10, 25, 50], // Define the page size options
  paginationSize: pageSize, // default pagination size: optional parameter to request a certain number of rows per page
  sortMode: 'remote',
  dataLoader: false, // Disable the data loader as the loading is relatively quick anyway

  ajaxURLGenerator(url, config, params) {
    const { page, size, filter, sort } = params;
    // Base URL:
    const generatedURL = new URL(url, window.location.origin);
    generatedURL.searchParams.append('page', page);
    generatedURL.searchParams.append('items', size);
    // Filters:
    if (filter.length > 0) generatedURL.searchParams.append('filter', filter[0].value ?? '');
    // Sorters:
    if (sort && sort.length > 0) generatedURL.searchParams.append(`sort[${sort[0].field}]`, sort[0].dir);

    return generatedURL;
  },

  ajaxResponse(url, params, response) {
    if (params.size === 0) {
      return {
        data: [],
        last_page: 0,
      };
    }

    // eslint-disable-next-line camelcase
    const last_page = response.last_page ?? 1;
    this.count = response.count ?? 0; // Seems needs to be stored in the instance (necessary for the search results count)

    return {
      data: response.data,
      // eslint-disable-next-line camelcase
      last_page,
    };
  },
});
