function urlBuilder(cell, generatedURL, includePage = true) {
  // Builds the url to maintain the current sort/filter/page state
  const table = cell.getTable();
  const sorts = table.getSorters().map((sort) => `sort[${sort.field}]=${sort.dir}`)[0];
  generatedURL.searchParams.append('items', table.getPageSize());

  if (includePage) {
    generatedURL.searchParams.append('page', table.getPage());
  }

  if (sorts) {
    // For the sorts to be picked up as an object in the rails controller it needs to come through as sort[field]=dir
    // and the = can not be escaped
    generatedURL.searchParams.append(sorts.split('=')[0], sorts.split('=')[1]);
  }
  if (table.getFilters().length > 0) {
    generatedURL.searchParams.append('filter', table.getFilters()[0].value);
  }

  return generatedURL;
}

export { urlBuilder };
