import { Controller } from '@hotwired/stimulus';
import { featureFlagEnabled } from '@root/utilities/utils';

export default class extends Controller {
  static outlets = ['campaign-editor', 'grapes-editor'];

  saveToGenerate() {
    if (!featureFlagEnabled('rich_html_editor')) {
      this.campaignEditorOutlet.clearLockPolling();
      this.campaignEditorOutlet.autoSave(true);
    }

    if (featureFlagEnabled('rich_html_editor')) {
      this.grapesEditorOutlet.clearLockPolling();
    }
  }
}
