import IconButton from '@components/sui/table/formatters/baseline/icon_button/icon_button.svelte';
import PencilOneIcon from '@components/sui/icon/svgs/PencilOneIcon.svelte';
import { edit_schema_attribute_path } from '@root/routes';

/**
 * Custom formatter function for the attribute actions cell
 */
function urlBuilder(cell) {
  // Builds the url to maintain the current sort/filter/page state
  const table = cell.getTable();
  const sorts = table.getSorters().map((sort) => `sort[${sort.field}]=${sort.dir}`)[0];
  const generatedURL = new URL(edit_schema_attribute_path(cell.getData().id), window.location.origin);

  generatedURL.searchParams.append('page', table.getPage());

  if (sorts) {
    // For the sorts to be picked up as an object in the rails controller it needs to come through as sort[field]=dir
    // and the = can not be escaped
    generatedURL.searchParams.append(sorts.split('=')[0], sorts.split('=')[1]);
  }
  if (table.getFilters().length > 0) {
    generatedURL.searchParams.append('filter', table.getFilters()[0].value);
  }

  return generatedURL;
}

function attributeActionsCell(cell) {
  const div = document.createElement('div');

  new IconButton({
    target: div,
    props: {
      Icon: PencilOneIcon,
      url: urlBuilder(cell),
      dataAttributes: {
        'data-turbo-frame': 'modal',
        'data-controller': 'attribute-setup',
      },
    },
  });

  return div;
}

export default { attributeActionsCell };
