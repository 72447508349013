export function openResponsiveImageDialog({ editor, existingImageNode = null }) {
  // Prepare initial form data if editing an existing <img>
  const initialData = {
    src: existingImageNode?.getAttribute('src') || '',
    alt: existingImageNode?.getAttribute('alt') || '',
  };

  editor.windowManager.open({
    title: 'Insert / Edit Image',
    body: {
      type: 'panel',
      items: [
        {
          type: 'input',
          name: 'src',
          label: 'Image URL',
        },
        {
          type: 'input',
          name: 'alt',
          label: 'Alt text (For accessibility)',
        },
      ],
    },
    buttons: [
      { type: 'cancel', text: 'Cancel' },
      { type: 'submit', text: 'Save', primary: true },
    ],
    initialData,
    onSubmit(api) {
      const data = api.getData();
      api.close();

      let imgElm = existingImageNode;
      if (!imgElm) {
        editor.insertContent('<img id="temp-img-placeholder" />');
        imgElm = editor.dom.get('temp-img-placeholder');
      }

      // Set src, alt
      imgElm.removeAttribute('id');
      imgElm.setAttribute('src', data.src || '');
      if (data.alt) {
        imgElm.setAttribute('alt', data.alt);
      } else {
        imgElm.removeAttribute('alt');
      }

      imgElm.style.width = '100%';
      imgElm.style.height = 'auto';
    },
  });
}

export default function registerResponsiveImage(editor) {
  editor.ui.registry.addMenuItem('responsiveImage', {
    icon: 'image',
    text: 'Edit image',
    onAction: () => {
      const node = editor.selection.getNode();
      if (node?.nodeName === 'IMG') {
        openResponsiveImageDialog({ editor, existingImageNode: node });
      } else {
        openResponsiveImageDialog({ editor, existingImageNode: null });
      }
    },
  });
  editor.ui.registry.addButton('responsiveImage', {
    icon: 'image',
    tooltip: 'Image',
    onAction: () => {
      openResponsiveImageDialog({ editor, existingImageNode: null });
    },
  });
  return {};
}
