import { Controller } from '@hotwired/stimulus';
import { featureFlagEnabled } from '@root/utilities/utils';

export default class extends Controller {
  static outlets = ['campaign-editor', 'grapes-editor'];

  static targets = ['lastSaved'];

  save() {
    if (!featureFlagEnabled('rich_html_editor')) {
      this.campaignEditorOutlet.submit();
    }

    if (featureFlagEnabled('rich_html_editor')) {
      this.grapesEditorOutlet.submit();
    }
  }

  updateTimeAgo(timeAgo) {
    this.lastSavedTarget.textContent = timeAgo;
  }
}
