import DeleteIcon from '@components/sui/icon/svgs/DeleteIcon.svelte';
import DotsVerticalIcon from '@components/sui/icon/svgs/DotsVerticalIcon.svelte';
import Dropdown from '@components/sui/table/formatters/baseline/dropdown/dropdown.svelte';
import { remove_contact_contact_list_path } from '@root/routes';
import { urlBuilder } from '@components/sui/table/utils';

/**
 * Contact List dropdown menu for contacts remove action
 */
function contactListContactActionsCell(cell) {
  const div = document.createElement('div');

  // Get the cell element and make it show the overflow to display the kebab menu
  cell.getElement().style.overflow = 'visible';
  const generatedURL = new URL(
    remove_contact_contact_list_path(cell.getData().contact_list_id, { contact_id: cell.getData().id }),
    window.location.origin,
  );

  new Dropdown({
    target: div,
    props: {
      DropdownIcon: DotsVerticalIcon,
      Items: [
        {
          label: 'Remove from list',
          icon: DeleteIcon,
          confirmation: 'Are you sure you want to remove from the list?',
          url: urlBuilder(cell, generatedURL, false),
          method: 'put',
        },
      ],
    },
  });

  return div;
}

export default { contactListContactActionsCell };
