export function openCTADialog({ editor, initialData = {} }) {
  editor.windowManager.open({
    title: 'Call To Action Settings',
    // We'll use the 'panel' style body so we can have multiple form fields:
    body: {
      type: 'panel',
      items: [
        {
          type: 'input',
          name: 'href',
          label: 'Link',
        },
        {
          type: 'input',
          name: 'text',
          label: 'Copy',
        },
        {
          type: 'colorinput',
          name: 'color',
          label: 'Background Color',
        },
        {
          type: 'input',
          name: 'radius',
          label: 'Round corners (px)',
        },
        {
          type: 'input',
          name: 'padding',
          label: 'Padding (px)',
        },
      ],
    },
    buttons: [
      {
        type: 'cancel',
        text: 'Cancel',
      },
      {
        type: 'submit',
        text: 'Save CTA',
        primary: true,
      },
    ],
    initialData: {
      href: initialData.href || '',
      text: initialData.text || '',
      color: initialData.color || '#0246C9', // A default color
      radius: initialData.radius || '4',
      padding: initialData.padding || '8',
    },
    onSubmit(api) {
      const data = api.getData();
      api.close();

      const style = `
        display:inline-block;
        text-decoration:none;
        background-color:${data.color};
        border-radius:${data.radius}px;
        padding:${data.padding}px;
        color:#fff;
      `.replace(/\s\s+/g, ' '); // remove extra whitespace

      // If we are editing an existing CTA, we may have an element reference:
      if (initialData.element) {
        // Update existing CTA
        const el = initialData.element;
        el.setAttribute('href', data.href);
        el.innerHTML = data.text;
        el.setAttribute('style', style);
        // Make sure it still has our identifying class:
        el.classList.add('cta-button');
      } else {
        // Insert new CTA
        const ctaHtml = `<a class="cta-button" href="${data.href}" style="${style}">${data.text}</a>`;
        editor.insertContent(ctaHtml);
      }
    },
  });
}

export default function registerCTAExtension(editor) {
  /**
   * Helper function to parse inline styles from the CTA <a> element
   */

  // Translates a CSS string into a CSSStyleDeclaration object
  function parseCTAStyles(styleString = '') {
    const temp = document.createElement('div');
    temp.style.cssText = styleString;
    return temp.style;
  }
  editor.ui.registry.addMenuItem('editCTA', {
    text: 'Edit CTA',
    onAction: () => {
      // We’ll retrieve the selected <a> element (the CTA)
      const node = editor.selection.getNode();
      if (node && node.nodeName === 'A' && node.classList.contains('cta-button')) {
        const styleObj = parseCTAStyles(node.getAttribute('style') || '');
        // Fill out the initial form data:
        openCTADialog({
          editor,
          initialData: {
            element: node,
            href: node.getAttribute('href'),
            text: node.innerText || node.textContent || '',
            color: styleObj['background-color'] || '#0246C9',
            radius: (styleObj['border-radius'] || '').replace('px', '') || '4',
            padding: (styleObj.padding || '').replace('px', '') || '8',
          },
        });
      }
    },
  });

  editor.ui.registry.addContextMenu('ctaContextMenu', {
    update: (element) => {
      if (element.nodeName === 'A' && element.classList.contains('cta-button')) {
        return ['editCTA'];
      }
      return [];
    },
  });

  return {
    cta: {
      type: 'menuitem',
      icon: 'browse',
      text: 'Call to action',
      onAction: () => {
        openCTADialog({ editor });
      },
    },
  };
}
