// registerSectionExtension.js
export function openSectionDialog({ editor, initialData = {} }) {
  editor.windowManager.open({
    title: 'Section Settings',
    body: {
      type: 'panel',
      items: [
        {
          type: 'colorinput',
          name: 'backgroundColor',
          label: 'Background Color',
        },
        {
          type: 'colorinput',
          name: 'textColor',
          label: 'Font Color',
        },
        {
          type: 'input',
          name: 'padding',
          label: 'Padding (px)',
        },
        {
          type: 'input',
          name: 'minHeight',
          label: 'Minimum Height (px)',
        },
      ],
    },
    buttons: [
      { type: 'cancel', text: 'Cancel' },
      { type: 'submit', text: 'Save Section', primary: true },
    ],
    initialData: {
      backgroundColor: initialData.backgroundColor || '#ffffff',
      textColor: initialData.textColor || '#000000',
      padding: initialData.padding || '10',
      minHeight: initialData.minHeight || '200',
    },
    onSubmit(api) {
      const data = api.getData();
      api.close();

      // Build the inline style string for the section:
      // We’ll merge the user inputs with the default "section" styles
      const style = `
        width: 95%;
        min-height: ${data.minHeight}px;
        background-color: ${data.backgroundColor};
        color: ${data.textColor};
        padding-top: ${data.padding}px;
        padding-bottom: ${data.padding}px;
        padding-left: ${data.padding}px;
        padding-right: ${data.padding}px;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
      `.replace(/\s\s+/g, ' ');

      if (initialData.element) {
        // Editing existing section
        const el = initialData.element;
        el.setAttribute('style', style);
      } else {
        // Creating a new section
        const sectionHtml = `
          <div class="email-section mceNonEditable" style="${style}">
            <div style="width:100%; height: 100%" contenteditable="true">
              <p>Section content goes here</p>
            </div>
          </div>
        `;
        editor.insertContent(sectionHtml);
      }
    },
  });
}

export default function registerSectionExtension(editor) {
  // Helper: parse inline CSS from an element
  function parseSectionStyles(styleString = '') {
    const temp = document.createElement('div');
    temp.style.cssText = styleString;
    return temp.style;
  }

  /**
   * Opens a dialog for creating/editing the "Section"
   */

  /**
   * 1) Context menu item to edit an existing section
   */
  editor.ui.registry.addMenuItem('editSection', {
    text: 'Edit Section',
    onAction: () => {
      const node = editor.selection.getNode();
      // Check if the selected node is our "section" (div with class "email-section")
      if (node && node.nodeName === 'DIV' && node.classList.contains('email-section')) {
        const styleObj = parseSectionStyles(node.getAttribute('style') || '');
        // Open the dialog with current styles
        openSectionDialog({
          editor,
          initialData: {
            element: node,
            backgroundColor: styleObj.backgroundColor || '#000000',
            textColor: styleObj.color || '#ffffff',
            // We assume the same value for left/right padding:
            padding: styleObj.paddingLeft ? styleObj.paddingLeft.replace('px', '') : '10',
          },
        });
      }
    },
  });

  /**
   * 2) Register context menu only for .email-section
   */
  editor.ui.registry.addContextMenu('sectionContextMenu', {
    update: (element) => {
      if (element.nodeName === 'DIV' && element.classList.contains('email-section')) {
        return ['editSection'];
      }
      return [];
    },
  });

  /**
   * 3) Return a menu item for your "kebab" or any other menu
   */
  return {
    section: {
      type: 'menuitem',
      text: 'Section',
      icon: 'browse', // choose any icon you like or a custom one
      onAction: () => {
        openSectionDialog({ editor });
      },
    },
  };
}
