// eslint-disable-next-line import/no-extraneous-dependencies
import { Module } from 'tabulator-tables';
import { logger } from '@root/utilities/logger';
import Pagination from './pagination.svelte';

export default class CustomPaginationModule extends Module {
  static moduleName = 'customPagination';

  static moduleInitOrder = 1;

  constructor(table) {
    super(table);
    this.registerTableOption('customPagination', true);
  }

  initialize() {
    if (this.options('customPagination')) {
      this.createPaginationComponent();

      // Set initial page only once when the table is built
      this.table.on('tableBuilt', async () => {
        await this.setPage(this.table.getPage()); // Set the initial page

        this.updatePagination(); // Update pagination when fully initialized
      });

      this.table.on('pageLoaded', this.updatePagination.bind(this));

      // Listen for data changes to update the pagination dynamically
      this.table.on('dataLoaded', () => {
        this.updatePagination();
        if (this.paginationComponent) {
          this.paginationComponent.$set({ className: '' });
        }
      });
    }
  }

  createPaginationComponent() {
    const paginationContainer = document.querySelector('#tabulator-pagination-singulate');
    if (!paginationContainer) {
      logger.debug('Pagination container not found. Ensure #tabulator-pagination-singulate exists in the HTML.');
      return;
    }

    // Override the default pagination container
    paginationContainer.innerHTML = '';
    paginationContainer.classList.add('!opacity-100');

    // Mount the Svelte Pagination component with default values
    this.paginationComponent = new Pagination({
      target: paginationContainer,
      props: {
        currentPage: 1, // Default to 1 initially, updates after tableBuilt happens
        lastPage: 1, // Default to 1 initially, updates after tableBuilt happens
        paginationSize: this.table.options.paginationSize,
        paginationOptions: this.table.options.paginationSizeSelector,
        paginationButtonCount: this.table.options.paginationButtonCount,
        className: 'hidden',
      },
    });

    // Listen to events from the Svelte component
    this.paginationComponent.$on('pageChange', (event) => this.setPage(event.detail));
    this.paginationComponent.$on('pageSizeChange', (event) => this.setPageSize(event.detail));
  }

  setPageSize(size) {
    this.table.setPageSize(size);
    this.setPage(1);
  }

  async setPage(page) {
    // Prevent recursive loops by checking if we're already on the desired page
    const currentPage = await this.getPage();
    if (currentPage === page) return;

    try {
      await this.table.setPage(page);
      await this.updatePagination();
    } catch (error) {
      logger.debug('Error setting page:', error);
    }
  }

  async updatePagination() {
    const currentPage = await this.getPage();
    const lastPage = this.table.getPageMax();

    // Update the Svelte component with the current pagination info
    this.paginationComponent.$set({
      currentPage,
      lastPage,
    });
  }

  getPage() {
    return this.table.getPage();
  }
}
